import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './styles/style.css';
import HomePage from './HomePage';
import NavigationBar from './NavigationBar';
import FeatureSection from './FeatureSection';
import FAQSection from './FAQSection';
import PrivacyPage from './PrivacyPage';

function App() {
  const [currentView, setCurrentView] = useState('home');

  const handleNavigation = (view) => {
    setCurrentView(view);
  };

  let content;
  switch (currentView) {
    case 'home':
      content = <HomePage />;
      break;
    case 'features':
      content = <FeatureSection />;
      break;
    case 'support':
      content = <FAQSection />;
      break;
    case 'privacy':
      content = <PrivacyPage />;
      break;
    // Add more cases for other views
    default:
      content = <HomePage />;
  }

  return (
    <div className='main-section'>
      <NavigationBar onNavigate={handleNavigation} />
      {content}
    </div> 
  )
}

ReactDOM.render(<App />, document.getElementById("root"));