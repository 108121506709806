import React from 'react';
import './styles/PrivacyPage.css';
import globeIcon from './images/globe.png'; // Ensure you have an icon for this
import aiIcon from './images/ai.png'; // Ensure you have an icon for this
import lens from './images/lens.png'; // Ensure you have an icon for this
import personIcon from './images/person.png'; // Ensure you have an icon for this

const privacyPoints = [
  {
    title: "🌐 Internet Connection",
    description: "NeuraStride requires internet to generate personalized missions using OpenAI's GPT-4.",
    icon: globeIcon,
  },
  {
    title: "🤖 AI-Powered Missions",
    description: "We use GPT-4 to create unique missions based on your inputs, securely and privately.",
    icon: aiIcon,
  },
  {
    title: "🔍 Transparent Data Usage",
    description: "We are transparent about the data we collect and how it’s used, keeping you informed every step of the way.",
    icon: lens,
  },
  {
    title: "👥 No Sharing Personal Data",
    description: "Your personal data is yours alone. We don’t sell or share your data with third parties.",
    icon: personIcon,
  },
  // Add more points as needed
];

export default function PrivacyPage() {
  return (
    <div className="privacy-page">
      <h2>Your privacy is super important to us.</h2>
      <p>Here’s the details on how we keep your data safe:</p>
      <div className="privacy-cards">
        {privacyPoints.map((point, index) => (
          <div key={index} className="privacy-card">
            <img src={point.icon} alt={point.title} className="privacy-icon" />
            <h3>{point.title}</h3>
            <p>{point.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
