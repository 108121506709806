import React, { useState } from 'react';
import './styles/FAQSection.css';

const questionsAndAnswers = [
  {
    question: "What's NeuraStride all about?",
    answer: "Think of it as your personal growth playground. You set goals, we drop daily missions. Level up IRL!",
  },
  {
    question: "How do I start using NeuraStride?",
    answer: "Download the app. Then input your goal, and boom – you get 3 daily missions. Nail them, ace the quiz, and you're golden.",
  },
  {
    question: "Why's it taking ages to generate missions?",
    answer: "Chillax, it's just our AI cookin' up the perfect missions for you. The more epic your goal, the more prep time needed.",
  },
  {
    question: "Any sick new features coming up?",
    answer: "Tons, fam! We're always cooking up something new and cool. Stay tuned!",
  },
  {
    question: "Can I slide into your DMs with feature ideas?",
    answer:  "Heck yeah! Hit us up on Insta @neurastride. We're all ears.",
  },
  {
    question: "Can I hype up NeuraStride on my socials?",
    answer: "For sure, spread the love! Just don’t bill us for it, lol.",
  },
];

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-section">
      <h2>Frequently asked questions</h2>
      <div className="faq-container">
        {questionsAndAnswers.map((qa, index) => (
          <div key={index} className={`faq-item ${activeIndex === index ? 'active' : ''}`}>
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              <span>{qa.question}</span>
              <span className="faq-toggle">{activeIndex === index ? '−' : '+'}</span>
            </div>
            <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
              <p>{qa.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;