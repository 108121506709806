import React, { useState } from 'react';
import './styles/NavigationBar.css';

export default function NavigationBar({ onNavigate }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleItemClick = (viewName) => {
    setMenuOpen(false); // Close the menu when an item is clicked
    onNavigate(viewName);
  };

  return (
    <nav className="navigation-bar">
      <div className="nav-brand">Neura<span className="highlight">Stride</span></div>
      <button className="menu-toggle" onClick={() => setMenuOpen(!menuOpen)}>
        ☰
      </button>
      <div className={`nav-items ${menuOpen ? 'open' : ''}`}>
        <a href="#home" onClick={() => handleItemClick('home')}>Home</a>
        <a href="#features" onClick={() => handleItemClick('features')}>Features</a>
        {/* <a href="#about" onClick={() => handleItemClick('about')}>About</a> */}
        <a href="#privacy" onClick={() => handleItemClick('privacy')}>Privacy</a>
        <a href="#support" onClick={() => handleItemClick('support')}>Support</a>
      </div>
    </nav>
  )
}

