import './styles/HeroSection.css';

export default function HomePage() {
    const handleDownloadClick = () => {
        window.location.href = 'https://apps.apple.com/gb/app/neurastride/id6473788657';
    };

    return (
        <div className="hero-section">
            <div className="release-info-container">
                <div className="release-tag">Version 1.2</div>
                <div className="update-link">Coming soon &rarr;</div>
            </div>
            <h1>Game On: <span className="highlight">Level Up Your Life</span></h1>
            <p>Embark on epic quests and power-up IRL with NeuraStride.</p>
            <button className="download-button" onClick={handleDownloadClick}>Join the Quest</button>
        </div>
    )
}
