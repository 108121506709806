import './styles/FeatureSection.css';
import React from 'react';
import bolt from './images/bolt.png'
import target from './images/target.png'
import chart from './images/chart.png'
import controller from './images/controller.png'

const features = [
  {
    title: 'AI-Generated Daily Missions',
    description: "Engaging daily missions created by OpenAI's GPT-4",
    icon: bolt, 
  },
  {
    title: 'Personalized Goal Setting',
    description: "Focus on personal development and skill-building",
    icon: target,
  },
  {
    title: 'Growth Journey',
    description: "Visualize your growth and monitor achievements",
    icon: chart, 
  },
  {
    title: "Unlock your Potential",
    description: "Master abilities, earn points, and level up",
    icon: controller, 
  }
];


export default function FeatureSection() {
    return (
        <div className="feature-section">
          <h2>👾 Next-Level Life: Play Your Way Up!</h2>
          <p>Dive into a world where every day's a new level. Snag personalized missions, crush goals, and level up IRL!</p>
        <div className="feature-cards">
          {features.map((feature, index) => (
            <div key={index} className="feature-card">
              <img src={feature.icon} alt={feature.title} />
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    )
}